*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

body {
	background-color: #000;
}

a,
a:hover,
a:active,
a:visited {
	color: #EBEBEB;
	text-decoration: none;
}

.links {
	color: white;
	z-index: 100;
	height: 48px;
	position: absolute;
	bottom: 0;
	width: 100%;
}
.links ul {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: flex-end;
}

.links ul li {
	text-decoration: none;
	list-style-type: none;
	padding: 18px;
}

@media (max-width: 640px) {
	.links ul li {
		padding: 1rem;
	}
}

.links ul i {
	font-size: 28px;
	padding-right: 0.5rem;
	padding-bottom: 1rem;
}

@media (max-width: 640px) {
	.links ul i {
		font-size: 24px;
	}
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
	z-index: -1;
}
